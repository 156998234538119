import React, { useContext } from "react";
import { Box, Button, Container, Heading, Text, Link } from "@chakra-ui/react";
import { languageContext } from "../../../../../context/LanguageContext";

const originText = {
  en: {
    title: "The concept's foundation",
    text: "Independent platform expert Martijn Arets is the initiator of GigCV. He has explored the concept of reputation data portability for platform workers in 2020 and 2021. This research is funded by UWV, FNV, Dutch Ministry of Economic Affairs and Climate, Seats2Meet, YoungOnes, Freshheads, the European Trade Union Institute, and Jobtech Gig (an initiative of the Swedish Labor Bureau). Results of this research are fundamental to the GigCV concept.",
    button: "Download the research report",
  },
  nl: {
    title: "De basis van dit concept",
    text: "Onafhankelijk platformexpert Martijn Arets is initatiefnemer van het KlusCV. Hij deed in 2020 en 2021 een verkenning naar de portabiliteit van reputatiedata voor platformwerkers. Het onderzoek is gesteund door UWV, FNV, Ministerie van Economische Zaken en Klimaat, Seats2Meet, YoungOnes, Freshheads, het European Trade Union Institute en Jobtech Gig (een initiatief van het Zweedse arbeidsbureau). De bevindingen uit dit onderzoek vormen de basis van het concept KlusCV.",
    button: "Download het onderzoeksrapport",
  },
};

const Origin: React.FC = () => {
  const language = useContext(languageContext);
  return (
    <Box bg="limelight" py={["16", "16", "32"]}>
      <Container maxW="container.md" textAlign="center">
        <Heading mb="8">{originText[language].title}</Heading>
        <Text mb="8">{originText[language].text}</Text>
        <a
          href={
            language === "nl"
              ? "/Eindrapport_onderzoek_data_delen_kluseconomie_2021.pdf"
              : "/Report_data_sharing_gig_economy_2021.pdf"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button whiteSpace="normal" colorScheme="turqoise" size="lg">
            {originText[language].button}
          </Button>
        </a>
      </Container>
    </Box>
  );
};

export default Origin;
