import React, { useContext } from "react";
import {
  Flex,
  Box,
  Button,
  Container,
  Heading,
  Text,
  Img,
} from "@chakra-ui/react";
import { languageContext } from "../../../../../context/LanguageContext";
//@ts-ignore
import bannerImg from "./banner.svg";
//@ts-ignore
import * as styles from "./banner.module.scss";

const bannerText = {
  en: {
    title: (
      <>
        Every platform worker a personal{" "}
        <Text className={styles.highlight} as="span">
          GigCV
        </Text>
      </>
    ),
    text: "GigCV is an easy tool for anyone working in or gaining work experience in the gig economy. With this open standard, you will be able to easily download your own reputation and transaction data, which serves as proof of your work experience and skills on connected platforms.",
  },
  nl: {
    title: (
      <>
        Iedere platformwerker een eigen{" "}
        <Text className={styles.highlight} as="span">
          KlusCV
        </Text>
      </>
    ),
    text: "KlusCV is een handige tool voor iedereen die werkervaring opdoet in de kluseconomie. Via deze open standaard download je eenvoudig jouw reputatie- en transactiedata. Zo heb je een bewijs van jouw werkervaring bij de aangesloten platformen.",
  },
};

const imageHeight = "600px";
const Banner: React.FC = () => {
  const language = useContext(languageContext);
  return (
    <Container
      maxW="container.xl"
      mt={["4", "4", "16"]}
      mb={["16", "16", "32"]}
    >
      <Flex alignItems="center">
        <Box flexBasis={["100%", "100%", "50%"]}>
          <Heading as="h1" fontSize={["3xl", "3xl", "6xl"]} mb="8">
            {bannerText[language].title}
          </Heading>
          <Text mb="8" fontSize="xl">
            {bannerText[language].text}
          </Text>
          {/* <Button size="lg" colorScheme="turqoise">
            Meer info
          </Button> */}
        </Box>
        <Box
          ml="calc(2/12 * 100%)"
          height={imageHeight}
          flexBasis="calc(3/12 * 100%)"
          position="relative"
          display={["none", "none", "block"]}
        >
          <Box position="absolute" top="0" left="0" width="100%">
            <Img height={imageHeight} maxWidth="initial" src={bannerImg} />
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default Banner;
