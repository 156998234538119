import React, { useContext } from "react";
import { Flex, Box, Container, Text, Img } from "@chakra-ui/react";
import { languageContext } from "../../../../../context/LanguageContext";

//@ts-ignore
import youngones from "./youngones.png";
//@ts-ignore
import charlycares from "./charlycares.png";
//@ts-ignore
import helpling from "./helpling.png";
//@ts-ignore
import levelworks from "./levelworks.png";
//@ts-ignore
import roamler from "./roamler.png";

const brandsText = {
  en: {
    text: "Affiliated platforms",
  },
  nl: {
    text: "Deelnemende platforms",
  },
};

const Brands: React.FC = () => {
  const language = useContext(languageContext);
  return (
    <Box p="16" my={["16", "16", "32"]} bg="lilalight">
      <Container maxW="container.xl">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexWrap={["wrap", "wrap", "nowrap"]}
        >
          <Box
            flexShrink={0}
            textAlign={["center", "center", "left"]}
            width={["100%", "100%", "auto"]}
          >
            <Text mb={["4", "4", "0"]} fontWeight="bold">
              {brandsText[language].text}
            </Text>
          </Box>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Box width={["50%", "50%", "calc(1/4 * 100% )"]} p="4">
              <Img src={charlycares} width="100%" />
            </Box>
            <Box width={["50%", "50%", "calc(1/4 * 100% )"]} p="4">
              <Img src={helpling} width="100%" />
            </Box>
            {/* <Box width={["50%", "50%", "calc(1/6 * 100% )"]} p="4">
              <Img src={levelworks} width="100%" />
            </Box> */}
            <Box width={["50%", "50%", "calc(1/4 * 100% )"]} p="4">
              <Img src={roamler} width="100%" />
            </Box>
            <Box width={["50%", "50%", "calc(1/4 * 100% )"]} p="4">
              <Img src={youngones} width="100%" />
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Brands;
