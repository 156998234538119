import React, { useContext } from "react";
import {
  Flex,
  Box,
  Container,
  Heading,
  Text,
  Img,
  Link,
  Button,
} from "@chakra-ui/react";
import { languageContext } from "../../../../../context/LanguageContext";
//@ts-ignore
import aboutImg from "./aboutimage.svg";

const aboutText = {
  en: {
    title: "About GigCV",
    text: (
      <>
        <Text mb="8" fontSize="lg">
          More and more people are gaining experience through their work via
          online platforms in the{" "}
          <Link href="https://platformwerk.nl/" isExternal>
            gig economy
          </Link>
          . These platforms make it easier to enter the labor market and the
          experience gained from platform work is a valuable asset in finding
          other employment.
        </Text>
        <Text mb="8" fontSize="lg">
          It has been difficult to utilize reputation and transaction data as a
          proof of work experience, as gig platforms have historically kept data
          strictly to themselves. In order to strengthen the position of
          platform workers on the labor market, gig platforms have come to an
          agreement on how to share data on transactions and reputations with
          workers. They consider this a logical next step, as they regard this
          data as belonging to the workers, not the platforms.
        </Text>
        <Text mb="8" fontSize="lg">
          Four gig platforms are leading by example with GigCV. More than 50,000
          gig workers now have access to their data and build a resume. GigCV is
          a package of an open standard, tools and documentation in which
          agreements regarding the sharing of data are defined. Initiators
          encourage all other (digital) mediators to join in.
        </Text>
        <Text mb="8" fontSize="lg">
          Anyone working via one of the affiliated platforms will be able to
          download a digital certificate with the click of a button. This GigCV
          will represent proof of their work experience and customer
          satisfaction.
        </Text>
        <a
          href={
            'Press-release-Platforms-are-providing-access-to-data-with-GigCV.pdf'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button whiteSpace="normal" colorScheme="turqoise" size="lg">
          Read the press release
          </Button>
        </a>
      </>
    ),
  },
  nl: {
    title: "Over KlusCV",
    text: (
      <>
        <Text mb="8" fontSize="lg">
          Steeds meer mensen doen werkervaring op via online platformen{" "}
          <Link href="https://platformwerk.nl/" isExternal>
            in de kluseconomie
          </Link>
          . Platformen verlagen zo de drempel naar de arbeidsmarkt. Ervaring via
          platformen is namelijk ook waardevol om aan de slag gaan op andere
          plekken.
        </Text>
        <Text mb="8" fontSize="lg">
          Tot nu toe was het niet mogelijk om reputatie- en transactiedata te
          gebruiken als bewijs van werkervaring, omdat klusplatformen de
          gegevens voor zichzelf hielden. Om de positie van platformwerkers op
          de arbeidsmarkt te versterken, hebben klusplatformen op initiatief en
          onder leiding van platformexpert en onderzoeker Martijn Arets
          afspraken gemaakt om de data rondom transactie en reputatie te delen
          met werkenden. Dat vinden zij logisch: het is tenslotte data van de
          platformwerker, niet van het platform. Een financiële bijdrage van
          financiële bijdrage van NSVP (Nederlandse Stichting voor
          Psychotechniek) en SBCM (Kenniscentrum en Arbeidsmarkt- &
          Opleidingsfonds voor de sociale werkgelegenheid) en de bijdrage van
          parners Freshheads en ICTRecht maakten de realisatie van KlusCV
          mogelijk.
        </Text>
        <Text mb="8" fontSize="lg">
          Vier klusplatformen geven het goede voorbeeld met KlusCV.{" "}
          <strong>
            Meer dan 50.000 platformwerkers hebben nu toegang tot hun data en
            kunnen een cv opbouwen.
          </strong>{" "}
          KlusCV is een pakket afspraken, standaarden en tools waarin afspraken
          rondom het delen van data zijn vastgelegd. De initatiefnemers moedigen
          alle andere (digitale) bemiddelaars aan om mee te doen.
        </Text>
        <Text mb="8" fontSize="lg">
          Wie werkt via de aangesloten platformen, kan nu met één druk op de
          knop een digitaal getuigschrift downloaden. Dit KlusCV is een bewijs
          van werkervaring en klanttevredenheid.
        </Text>
        <a
          href={
            'Persbericht-Platformen-geven-toegang-tot-data-met-KlusCV.pdf'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button whiteSpace="normal" colorScheme="turqoise" size="lg">
          Lees hier het persbericht
          </Button>
        </a>
      </>
    ),
  },
};

const imageHeight = "600px";

const About: React.FC = () => {
  const language = useContext(languageContext);
  return (
    <Container maxW="container.xl" my={["16", "16", "32"]}>
      <Flex alignItems="center">
        <Box flexBasis={["100%", "100%", "50%"]}>
          <Heading as="h1" fontSize={["3xl", "3xl", "5xl"]} mb="8">
            {aboutText[language].title}
          </Heading>
          {aboutText[language].text}
        </Box>
        <Box
          ml="calc(2/12 * 100%)"
          height={imageHeight}
          flexBasis="calc(3/12 * 100%)"
          position="relative"
          display={["none", "none", "block"]}
        >
          <Box position="absolute" top="0" left="0" width="100%">
            <Img height={imageHeight} maxWidth="initial" src={aboutImg} />
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default About;
