import React, { useContext } from "react";
import {
  Flex,
  Box,
  Button,
  Container,
  Heading,
  Text,
  Img,
  Link,
} from "@chakra-ui/react";
import { languageContext } from "../../../../../context/LanguageContext";
//@ts-ignore
import contactImg from "./contact.svg";

const contactText = {
  en: {
    title: "Interested? Like to participate? Got a pressing question?",
    text: "Feel free to contact us and we’ll answer as best we can.",
  },
  nl: {
    title: "Interesse gewekt? Wil je meedoen? Of gewoon een vraag?",
    text: "Neem gerust contact op en we helpen je graag verder.",
  },
};

const Contact: React.FC = () => {
  const language = useContext(languageContext);
  return (
    <Container maxW="container.xl" my={["16", "16", "32"]}>
      <Flex alignItems="center">
        <Box flexBasis={["100%", "100%", "40%"]}>
          <Heading as="h2" fontSize={["3xl", "3xl", "5xl"]} mb="8">
            {contactText[language].title}
          </Heading>
          <Text mb="8" fontSize="xl">
            {contactText[language].text}
          </Text>
          <Link
            href={`mailto:martijn@collaborative-economy.com?subject=${
              language === "nl" ? "Reactie vanaf KlusCV" : "Response from GigCV"
            }`}
            isExternal
          >
            <Button size="lg" colorScheme="turqoise">
              {language === "nl" ? "Neem contact Op" : "Contact"}
            </Button>
          </Link>
        </Box>
        <Box marginLeft="calc(2/12 * 100%)" display={["none", "none", "block"]}>
          <Img src={contactImg} />
        </Box>
      </Flex>
    </Container>
  );
};

export default Contact;
