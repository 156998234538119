import React, { useContext } from "react";
import Banner from "./components/banner/Banner";
import Brands from "./components/brands/Brands";

import About from "./components/about/About";
import Origin from "./components/origin/Origin";
import Contact from "./components/contact/Contact";
import Layout from "../../layout/layout";

const Home: React.FC = () => {
  return (
    <Layout title={{ nl: "KlusCV", en: "GigCV" }}>
      <Banner />
      <Brands />
      <About />
      <Origin />
      <Contact />
    </Layout>
  );
};

export default Home;
